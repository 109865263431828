import * as React from 'react';
import MarketingPage from './MarketingPage';
import { Helmet } from 'react-helmet';

export default function App() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ParcelDelivery",
    "name": "Skala Red Logística",
    "description": "Transporte de carga frágil y doble altura. Reducimos roturas en un 30% y optimizamos costos logísticos.",
    "url": "https://skalaredlogistica.com/",
    "telephone": "+57 310 8807815"
  };
  return (
    <>
      <Helmet>
        <title>Skala Red Logística | Transporte de Otro Nivel</title>
        <meta
          name="description"
          content="Skala Red Logística: especialistas en carga frágil y doble altura. Con tecnología de doble nivel, reducimos roturas en un 30% y costos logísticos."
        />
        {/* Metadatos Open Graph para redes sociales */}
        <meta property="og:title" content="Skala Red Logística | Transporte de Otro Nivel" />
        <meta
          property="og:description"
          content="Optimiza el transporte de mercancías delicadas a nivel nacional con tecnología de doble nivel."
        />
        <meta property="og:image" content="https://skalaredlogistica.com/static/images/skala.png" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <MarketingPage></MarketingPage>
    </>
  );
}
