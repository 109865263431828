import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import RouteIcon from '@mui/icons-material/Route';
import SensorsIcon from '@mui/icons-material/Sensors';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import IntegrationInstructionsRoundedIcon from '@mui/icons-material/IntegrationInstructionsRounded';
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded';
import WarehouseRoundedIcon from '@mui/icons-material/WarehouseRounded';

const items = [
  {
    icon: <WarehouseRoundedIcon />,
    title: 'Tecnología de Doble Nivel',
    description:
      'Ampliamos tu capacidad de carga un 40% sin aumentar viajes. Altura útil de 2.60m para mercancías frágiles con protección certificada contra vibraciones.',
  },
  {
    icon: <SensorsIcon />,
    title: 'Eco-Transporte Inteligente',
    description:
      'Reducción del 18% en emisiones CO₂ gracias a rutas optimizadas.',
  },
  {
    icon: <InsightsRoundedIcon />,
    title: 'Ahorros Comprobados',
    description:
      'Empresas aliadas reportan ahorros de $289M anuales en logística.',
  },
  {
    icon: <VerifiedUserRoundedIcon />,
    title: 'Servicio Premium 24/7',
    description:
      'Asesoramiento especializado en carga frágil y seguimiento personalizado.',
  },
  {
    icon: <IntegrationInstructionsRoundedIcon />,
    title: 'Tiempos de Respuesta Ágiles',
    description:
      'Carga/descarga 35% más rápida con nuestra plataforma integrada. Protocolos especializados para mercancías urgentes.',
  },
  {
    icon: <RouteIcon />,
    title: 'Rutas Estratégicas',
    description:
      'Operación prioritaria en corredores clave.',
  },
];

export default function Highlights() {
  return (
    <Box
      id="Tecnologia"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'grey.900',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '80%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
            Tecnología Especializada para Carga Frágil
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400', fontSize: '1.1rem' }}>
            Soluciones diseñadas para la logística de alto valor con tecnología de doble nivel certificada
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1.5}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  border: '1px solid hsl(0, 87%, 50%)',
                  backgroundColor: 'hsl(0, 0.00%, 0.00%)',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)'
                  }
                }}
              >
                <Box sx={{ color: 'hsl(0, 87%, 50%)' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom variant="h6" sx={{ fontWeight: 600 }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}