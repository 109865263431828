import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';

const whiteLogos = [
  '/static/images/tiendas-ara.svg',
  '/static/images/tiendas-d1.svg',  
  '/static/images/huevos-kikes.svg',
  '/static/images/opl-carga.svg',
  '/static/images/viatela-carga.svg',
];

const darkLogos = [
  '/static/images/tiendas-ara.svg',
  '/static/images/tiendas-d1.svg',  
  '/static/images/huevos-kikes.svg',
  '/static/images/opl-carga.svg',
  '/static/images/viatela-carga.svg',
];

const logoStyle = {
  height: '120px',
  margin: '0 32px',
  opacity: 0.7,
  flexShrink: 0,
};

const animationKeyframes = {
  '@keyframes scroll': {
    '0%': { transform: 'translateX(0%)' },
    '100%': { transform: 'translateX(-100%)' },
  },
};

export default function LogoCollection() {
  const theme = useTheme();
  const originalLogos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;
  const duplicatedLogos = [...originalLogos, ...originalLogos, ...originalLogos];

  return (
    <Box id="logoCollection" sx={{ py: 4, overflow: 'hidden' }}>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        sx={{ color: 'text.secondary' }}
      >
        Ya confian en nosotros...
      </Typography>
      <Box
        sx={{
          position: 'relative',
          mt: 2,
          ...animationKeyframes,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: 'fit-content',
            animation: 'scroll 40s linear infinite',
          }}
        >
          {duplicatedLogos.map((logo, index) => (
            <Box
              key={index}
              component="span"
              sx={{
                ...logoStyle,
                '&:hover': { opacity: 1 },
              }}
            >
              <img
                src={logo}
                alt={(logo.split('/').pop() ?? '')
                  .replace('.svg', '')
                  .replace(/([A-Z])/g, ' $1')
                  .trim()}
                style={{ width: 'auto', height: '100%' }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}