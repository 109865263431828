import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import WarehouseRoundedIcon from '@mui/icons-material/WarehouseRounded';

const tiers = [
  {
    title: 'Generadores de Carga',
    icon: <WarehouseRoundedIcon color="primary" sx={{ fontSize: 40 }} />,
    description: [
      'Amplíe su capacidad con transporte doble nivel sin costos adicionales',
      'Reducción del 30% en roturas de carga frágil',
      'Rutas prioritarias con entregas a tiempo',
      'Ahorros comprobados de hasta $289M anuales en costos logísticos',
    ],
    buttonText: 'Optimizar mi logística',
    buttonVariant: 'contained',
    buttonColor: 'primary',
    buttonLink: 'https://api.whatsapp.com/send/?phone=573108807815&text=Buen%20día!%20Quiero%20optimizar%20mis%20envíos%20con%20doble%20nivel',
  },
  {
    title: 'Transportadoras',
    icon: <LocalShippingRoundedIcon color="primary" sx={{ fontSize: 40 }} />,
    description: [
      'Gestión integral de flotas con tecnología doble nivel',
      'Reducción del 18% en emisiones CO₂ con rutas optimizadas',
      'Monitoreo IoT de temperatura/humedad para carga delicada',
      'Menos en costos operativos con nuestra plataforma inteligente',
    ],
    buttonText: 'Modernizar mi flota',
    buttonVariant: 'contained',
    buttonColor: 'primary',
    buttonLink: 'https://api.whatsapp.com/send/?phone=573108807815&text=Necesito%20modernizar%20mi%20flota%20con%20doble%20nivel',
  },
  {
    title: 'Transportistas',
    icon: <GroupsRoundedIcon color="primary" sx={{ fontSize: 40 }} />,
    description: [
      'Gane más con viajes premium para carga especializada',
      'Capacitación certificada en manipulación de carga frágil',
      'Pagos más rápidos con nuestro sistema automatizado',
      'Acceso prioritario a rutas estratégicas de alto volumen',
    ],
    buttonText: 'Unirme a Skala',
    buttonVariant: 'contained',
    buttonColor: 'primary',
    buttonLink: 'https://api.whatsapp.com/send/?phone=573108807815&text=Quiero%20ser%20transportista%20certificado%20Skala',
  },
];

export default function Solutions() {
  return (
    <Container
      id="soluciones"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
        bgcolor: 'background.paper',
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '80%' },
          textAlign: 'center',
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ 
            color: 'text.primary',
            fontWeight: 'bold',
            mb: 3
          }}
        >
          Ventajas del Transporte de Otro Nivel
        </Typography>
        <Typography variant="h6" sx={{ color: 'text.secondary', mb: 4 }}>
          Soluciones especializadas para cada actor logístico
        </Typography>
      </Box>
      
      <Grid container spacing={4} justifyContent="center">
        {tiers.map((tier) => (
          <Grid item xs={12} sm={6} md={4} key={tier.title}>
            <Card
              sx={{
                p: 3,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: 4,
                },
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Box sx={{ textAlign: 'center', mb: 3 }}>
                  {tier.icon}
                </Box>
                <Typography 
                  component="h3" 
                  variant="h5" 
                  gutterBottom
                  sx={{ 
                    fontWeight: '600',
                    color: 'primary.main',
                    textAlign: 'center'
                  }}
                >
                  {tier.title}
                </Typography>
                
                <Divider sx={{ my: 2 }} />
                
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{ 
                      py: 1,
                      display: 'flex',
                      gap: 1.5,
                      alignItems: 'flex-start'
                    }}
                  >
                    <CheckCircleRoundedIcon
                      color="primary"
                      sx={{ width: 20, mt: '2px' }}
                    />
                    <Typography variant="body1" component="div">
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              
              <CardActions sx={{ justifyContent: 'center', mt: 'auto' }}>
                <Button
                  fullWidth
                  variant={tier.buttonVariant as 'outlined' | 'contained'}
                  color={tier.buttonColor as 'primary' | 'secondary'}
                  href={tier.buttonLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ 
                    fontWeight: 'bold',
                    py: 1.5,
                    fontSize: '1.1rem'
                  }}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}